export const FF_REFRESH_ITEM = 'keap.sharing.refresh.items';
export const FF_MISSING_FEATURES = 'keap.sharing.missingFeatures.error';
export const FF_INTERNAL_CART_LINK = 'keap.sharing.internal.cart.link';
export const FF_GENERATE_CART_LINK = 'keap.sharing.new.cart.links';

// Shareable resource types
export const FF_SHARING_APPOINTMENTS = 'keap.sharing.type.appointments';
export const FF_SHARING_EASY_AUTOMATIONS = 'keap.sharing.type.easyAutomation';
export const FF_SHARING_SAVED_SEARCH_CONTACT = 'keap.sharing.type.savedSearch.contact';
export const FF_SHARING_SAVED_SEARCH_COMPANY = 'keap.sharing.type.savedSearch.company';
export const FF_SHARING_SAVED_SEARCH_ORDER = 'keap.sharing.type.savedSearch.order';
export const FF_SHARING_SAVED_SEARCH_REFERRAL_PARTNER = 'keap.sharing.type.savedSearch.referralPartner';
export const FF_SHARING_SAVED_SEARCH_SUBSCRIPTION = 'keap.sharing.type.savedSearch.subscription';
export const FF_SHARING_SAVED_SEARCH_VISITOR = 'keap.sharing.type.savedSearch.visitor';
export const FF_SHARING_TEXT_TEMPLATE_EMAIL = 'keap.sharing.type.textTemplate.email';
export const FF_SHARING_TEXT_TEMPLATE_SMS_BROADCAST = 'keap.sharing.type.textTemplate.smsBroadcast';
export const FF_SHARING_TEXT_TEMPLATE_SMS_AUTOMATION = 'keap.sharing.type.textTemplate.smsAutomation';
export const FF_SHARING_TEXT_TEMPLATE_SMS_CUSTOM = 'keap.sharing.type.textTemplate.smsCustom';
export const FF_SHARING_AFFILIATE_CUSTOM_FIELDS = 'keap.sharing.type.affiliateCustomField';
export const FF_SHARING_ROLES = 'keap.sharing.type.role';
